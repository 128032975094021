import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const localizedFormat = require('dayjs/plugin/localizedFormat')

const authBaseUrl = '/admin'

// params = {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListUsers = (params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`${authBaseUrl}/users`, params)
  )
}

export const getUserStatistic = () => {
  return axiosClient.get('statistics/user')
}

export const getDetailUser = (id) => {
  return axiosClient.get(`${authBaseUrl}/users/${id}`)
}

export const getPostsData = (params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`${authBaseUrl}/posts`, params)
  )
}

export const lockUser = (id) => {
  return axiosClient.put(`${authBaseUrl}/users/${id}/lock`)
}

export const unlockUser = (id) => {
  return axiosClient.put(`${authBaseUrl}/users/${id}/unlock`)
}

export const deleteUser = (id) => {
  return axiosClient.delete(`${authBaseUrl}/users/${id}`)
}

export const resetPassword = (id, data) => {
  return axiosClient.put(`${authBaseUrl}/users/${id}/reset-password`, data)
}

export const getTableData = (id, tab, params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`/users/${id}/${tab}`, params)
  )
}

export const getReports = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings('/reports', params))
}

export const getReportsSummary = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings('/reports/summary', params))
}

export const deleteReport = (id) => {
  return axiosClient.delete(`/reports/${id}`)
}

export const deletePost = (id) => {
  return axiosClient.delete(`/posts/${id}`)
}

export const lockPost = (id) => {
  return axiosClient.put(`${authBaseUrl}/posts/${id}/lock`)
}

export const unlockPost = (id) => {
  return axiosClient.put(`${authBaseUrl}/posts/${id}/unlock`)
}

export const deleteReview = (id) => {
  return axiosClient.delete(`/user-reviews/${id}`)
}

export const formatDataMediaCard = (data) => {
  const creator = data.creator || {}
  const result = {
    price: data.price,
    srcImg: data.thumbnail,
    priceUnit: data.price_unit,
    location: {
      lat: data.lat,
      lng: data.lng,
      locationName: data.location_name,
    },
    title: data.title,
    categoryName: data?.category?.name || '',
    subCategoryName: data?.sub_category?.name || '',
    totalLikes: data?.total_likes || data?.total_reaction || 0,
    totalComments: data?.total_comments ?? 0,
    totalViews: data.total_views,
    status: data.status,
    priceType: data?.price_type || '',
    totalPlannedAttend: data.total_planning_to_attend_event,
    openTime: data.open_time,
    endTime: data.end_time,
    creator: {
      creatorAvatar: creator.avatar,
      creatorName: creator.full_name,
      creatorRating: creator.rating,
      lastActive: creator.last_active_at,
    },
  }
  return result
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export function formatDatesWithTime(openTime, endTime, isExpired) {
  const openDate = dayjs(openTime).tz(dayjs.tz.guess())
  const endDate = dayjs(endTime).tz(dayjs.tz.guess())

  return isExpired
    ? `${openDate.format('h:mma MMM D').toUpperCase()} - ${endDate.format('h:mma MMM D').toUpperCase()}`
    : `${openDate.format('MMM D').toUpperCase()} - ${endDate.format('MMM D').toUpperCase()}`
}

import { types } from './constants'

// {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListUsers = (payload) => ({
  type: types.GET_LIST_USERS_REQUEST,
  payload,
})

export const getListUsersSuccess = (payload) => ({
  type: types.GET_LIST_USERS_SUCCESS,
  payload,
})

export const getListUsersFailed = (payload) => ({
  type: types.GET_LIST_USERS_FAILED,
  payload,
})

export const getUserStatistic = () => ({
  type: types.GET_USER_STATISTIC_REQUEST,
})

export const getUserStatisticSuccess = (payload) => ({
  type: types.GET_USER_STATISTIC_SUCCESS,
  payload,
})

export const getUserStatisticFailed = (payload) => ({
  type: types.GET_USER_STATISTIC_FAILED,
  payload,
})

export const getDetailUser = (id, navigate) => ({
  type: types.GET_DETAIL_USER_REQUEST,
  payload: { id, navigate },
})

export const getDetailUserSuccess = (payload) => ({
  type: types.GET_DETAIL_USER_SUCCESS,
  payload,
})

export const getDetailUserFailed = (payload) => ({
  type: types.GET_DETAIL_USER_FAILED,
  payload,
})

export const getPostsData = (payload) => ({
  type: types.GET_POSTS_DATA_REQUEST,
  payload,
})

export const getPostsDataSuccess = (payload) => ({
  type: types.GET_POSTS_DATA_SUCCESS,
  payload,
})

export const getPostsDataFailed = (payload) => ({
  type: types.GET_POSTS_DATA_FAILED,
  payload,
})

export const getTableData = (payload) => ({
  type: types.GET_TABLE_DATA_REQUEST,
  payload,
})

export const getTableDataSuccess = (payload) => ({
  type: types.GET_TABLE_DATA_SUCCESS,
  payload,
})

export const getTableDataFailed = (payload) => ({
  type: types.GET_TABLE_DATA_FAILED,
  payload,
})

export const getReports = (payload) => ({
  type: types.GET_REPORTS_REQUEST,
  payload,
})

export const getReportsSuccess = (payload) => ({
  type: types.GET_REPORTS_SUCCESS,
  payload,
})

export const getReportsFailed = (payload) => ({
  type: types.GET_REPORTS_FAILED,
  payload,
})

export const deleteReport = (reportId, params, callback) => ({
  type: types.DELETE_REPORT,
  payload: { id: reportId, params, callback },
})

export const lockPost = (id, callback) => ({
  type: types.LOCK_POST,
  payload: { id, callback },
})

export const unlockPost = (id, callback) => ({
  type: types.UNLOCK_POST,
  payload: { id, callback },
})

export const deletePost = (id, callback) => ({
  type: types.DELETE_POST,
  payload: { id, callback },
})

export const getSummaryReports = (payload) => ({
  type: types.GET_SUMMARY_REPORTS_REQUEST,
  payload,
})

export const getSummaryReportsSuccess = (payload) => ({
  type: types.GET_SUMMARY_REPORTS_SUCCESS,
  payload,
})

export const getSummaryReportsFailed = (payload) => ({
  type: types.GET_SUMMARY_REPORTS_FAILED,
  payload,
})

export const lockUser = (id, callback) => ({
  type: types.LOCK_USER_REQUEST,
  payload: { id, callback },
})

export const lockUserSuccess = (payload) => ({
  type: types.LOCK_USER_SUCCESS,
  payload,
})

export const lockUserFailed = (payload) => ({
  type: types.LOCK_USER_FAILED,
  payload,
})

export const unlockUser = (id, callback) => ({
  type: types.UNLOCK_USER_REQUEST,
  payload: { id, callback },
})

export const unlockUserSuccess = (payload) => ({
  type: types.UNLOCK_USER_SUCCESS,
  payload,
})

export const unlockUserFailed = (payload) => ({
  type: types.UNLOCK_USER_FAILED,
  payload,
})

export const deleteUser = (id, callback) => ({
  type: types.DELETE_USER_REQUEST,
  payload: { id, callback },
})

export const deleteUserSuccess = (payload) => ({
  type: types.DELETE_USER_SUCCESS,
  payload,
})

export const deleteUserFailed = (payload) => ({
  type: types.DELETE_USER_FAILED,
  payload,
})

export const resetPassword = (payload) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload,
})

export const resetPasswordSuccess = (payload) => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload,
})

export const resetPasswordFailed = (payload) => ({
  type: types.RESET_PASSWORD_FAILED,
  payload,
})

export const deleteReview = (id, callback) => ({
  type: types.DELETE_REVIEW,
  payload: { id, callback },
})
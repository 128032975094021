export const types = {
  GET_LIST_USERS_REQUEST: "user/GET_LIST_USERS_REQUEST",
  GET_LIST_USERS_SUCCESS: "user/GET_LIST_USERS_SUCCESS",
  GET_LIST_USERS_FAILED: "user/GET_LIST_USERS_FAILED",

  GET_USER_STATISTIC_REQUEST: "user/GET_USER_STATISTIC_REQUEST",
  GET_USER_STATISTIC_SUCCESS: "user/GET_USER_STATISTIC_SUCCESS",
  GET_USER_STATISTIC_FAILED: "user/GET_USER_STATISTIC_FAILED",

  GET_DETAIL_USER_REQUEST: "user/GET_DETAIL_USER_REQUEST",
  GET_DETAIL_USER_SUCCESS: "user/GET_DETAIL_USER_SUCCESS",
  GET_DETAIL_USER_FAILED: "user/GET_DETAIL_USER_FAILED",

  GET_POSTS_DATA_REQUEST: "user/GET_POSTS_DATA_REQUEST",
  GET_POSTS_DATA_SUCCESS: "user/GET_POSTS_DATA_SUCCESS",
  GET_POSTS_DATA_FAILED: "user/GET_POSTS_DATA_FAILED",

  GET_TABLE_DATA_REQUEST: "user/GET_TABLE_DATA_REQUEST",
  GET_TABLE_DATA_SUCCESS: "user/GET_TABLE_DATA_SUCCESS",
  GET_TABLE_DATA_FAILED: "user/GET_TABLE_DATA_FAILED",

  GET_REPORTS_REQUEST: "user/GET_REPORTS_REQUEST",
  GET_REPORTS_SUCCESS: "user/GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILED: "user/GET_REPORTS_FAILED",

  DELETE_REPORT: "user/DELETE_REPORT",

  DELETE_POST: "user/DELETE_POST",
  LOCK_POST: "user/LOCK_POST",
  UNLOCK_POST: "user/UNLOCK_POST",

  DELETE_REVIEW: "user/DELETE_REVIEW",

  GET_SUMMARY_REPORTS_REQUEST: "user/GET_SUMMARY_REPORTS_REQUEST",
  GET_SUMMARY_REPORTS_SUCCESS: "user/GET_SUMMARY_REPORTS_SUCCESS",
  GET_SUMMARY_REPORTS_FAILED: "user/GET_SUMMARY_REPORTS_FAILED",

  LOCK_USER_REQUEST: "user/LOCK_USER_REQUEST",
  LOCK_USER_SUCCESS: "user/LOCK_USER_SUCCESS",
  LOCK_USER_FAILED: "user/LOCK_USER_FAILED",

  UNLOCK_USER_REQUEST: "user/UNLOCK_USER_REQUEST",
  UNLOCK_USER_SUCCESS: "user/UNLOCK_USER_SUCCESS",
  UNLOCK_USER_FAILED: "user/UNLOCK_USER_FAILED",

  DELETE_USER_REQUEST: "user/DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "user/DELETE_USER_SUCCESS",
  DELETE_USER_FAILED: "user/DELETE_USER_FAILED",

  RESET_PASSWORD_REQUEST: "user/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "user/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "user/RESET_PASSWORD_FAILED",

};

import { takeLatest, call, put, fork } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'

import { showSnackbar } from 'containers/Snackbar/store/actions'

function* getListUsers(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const params = action.payload

  try {
    const { data } = yield call(services.getListUsers, params)
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
            index: index + 1 + (params.page - 1) * params.limit,
            ...item,
          }))
        : []

    yield put(
      actions.getListUsersSuccess({ ...data, data: { ...data.data, records } })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getListUsersFailed(data))
  }
}

function* getUserStatistic() {
  try {
    const { data } = yield call(services.getUserStatistic)
    yield put(actions.getUserStatisticSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getUserStatisticFailed(data))
  }
}

function* getDetailUsers(action) {
  const { id, navigate } = action.payload
  try {
    const { data } = yield call(services.getDetailUser, id)
    yield put(actions.getDetailUserSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    navigate('/users')
    yield put(actions.getDetailUserFailed(data))
  }
}

function* getPostsData(action) {
  try {
    const { data } = yield call(services.getPostsData, action.payload)
    yield put(actions.getPostsDataSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getPostsDataFailed(data))
  }
}

function* getTableData(action) {
  const { id, tab, params } = action.payload
  try {
    const { data } = yield call(services.getTableData, id, tab, params)
    yield put(actions.getTableDataSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getTableDataFailed(data))
  }
}

function* getReports(action) {
  const params = action.payload
  try {
    const { data } = yield call(services.getReports, params)
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
            index: index + 1 + (params.page - 1) * params.limit,
            ...item,
          }))
        : []

    yield put(
      actions.getReportsSuccess({ ...data, data: { ...data.data, records } })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getReportsFailed(data))
  }
}

function* getSummaryReports(action) {
  try {
    const { data } = yield call(services.getReportsSummary, action.payload)
    yield put(actions.getSummaryReportsSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getSummaryReportsFailed(data))
  }
}

function* lockUser(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.lockUser, id)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Lock user success',
      })
    )
    callback && callback()
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Lock user error',
      })
    )
  }
}

function* unlockUser(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.unlockUser, id)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Unlock user success',
      })
    )
    callback && callback()
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Unlock user error',
      })
    )
  }
}

function* deleteUser(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deleteUser, id)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Delete user success',
      })
    )
    callback && callback()
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Delete user error',
      })
    )
  }
}

function* resetPassword(action) {
  const { id, dataSubmit, callback } = action.payload
  try {
    yield call(services.resetPassword, id, dataSubmit)
    callback()
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Reset password failed',
      })
    )
  }
}

function* deleteReport(action) {
  const { id, callback, params } = action.payload
  try {
    yield call(services.deleteReport, id)
    yield fork(getSummaryReports, { payload: params })
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Delete report id ${id} success`,
      })
    )
    callback()
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: `Delete report id ${id} failed`,
      })
    )
  }
}

function* lockPost(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.lockPost, id)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Lock post ${id} success`,
      })
    )
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: `Lock post ${id} failed`,
      })
    )
  }
}

function* unlockPost(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.unlockPost, id)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Unlock post ${id} success`,
      })
    )
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: `Unlock post ${id} failed`,
      })
    )
  }
}

function* deletePost(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deletePost, id)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Delete post ${id} success`,
      })
    )
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: `Delete post ${id} failed`,
      })
    )
  }
}

function* deleteReview(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deleteReview, id)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Delete review ${id} success`,
      })
    )
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: `Delete review ${id} failed`,
      })
    )
  }
}

export default function* usersWatcher() {
  yield takeLatest(types.GET_LIST_USERS_REQUEST, getListUsers)
  yield takeLatest(types.GET_USER_STATISTIC_REQUEST, getUserStatistic)
  yield takeLatest(types.GET_DETAIL_USER_REQUEST, getDetailUsers)
  yield takeLatest(types.GET_POSTS_DATA_REQUEST, getPostsData)
  yield takeLatest(types.GET_TABLE_DATA_REQUEST, getTableData)
  yield takeLatest(types.GET_REPORTS_REQUEST, getReports)
  yield takeLatest(types.GET_SUMMARY_REPORTS_REQUEST, getSummaryReports)
  yield takeLatest(types.LOCK_USER_REQUEST, lockUser)
  yield takeLatest(types.UNLOCK_USER_REQUEST, unlockUser)
  yield takeLatest(types.DELETE_USER_REQUEST, deleteUser)
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword)
  yield takeLatest(types.DELETE_REPORT, deleteReport)
  yield takeLatest(types.LOCK_POST, lockPost)
  yield takeLatest(types.UNLOCK_POST, unlockPost)
  yield takeLatest(types.DELETE_POST, deletePost)
  yield takeLatest(types.DELETE_REVIEW, deleteReview)
}
